import { Link, LinkProps } from '@mui/material';
import { ReactComponent as Logo } from './logoWithTagLine.svg';
type Props = {
  height: LinkProps['height'];
};
export const LogoWithTagLine = ({
  height
}: Props) => <Link href="/" title="Go to Quotebeam homepage" target="_blank" rel="noopener noreferrer" sx={{
  height: height,
  display: 'flex'
}} data-sentry-element="Link" data-sentry-component="LogoWithTagLine" data-sentry-source-file="LogoWithTagLine.tsx">
    <Logo height="100%" data-sentry-element="Logo" data-sentry-source-file="LogoWithTagLine.tsx" />
  </Link>;