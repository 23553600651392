'use client';

import { FormEventHandler, useState, useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Checkbox, FormControlLabel, InputProps, Link as MuiLink, TextField, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { LinkOnClickOrRedirect } from '@/src/components/Common/LinkOnClickOrRedirect';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { LoginView } from '../LoginView/LoginView';
import { useSignupRequest } from '../MultiStepSignupForm/useSignupRequest';
export const PASSWORD_MIN_LENGTH = 6;
type ExpectedQuery = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
};
type Props = {
  overrideFormAction?: string;
  userInviteToken: string;
  pendingUserToken: string;
  disableCompanyNameEdit: boolean;
  isBuyer: boolean;
  isSupplier: boolean;
  isManufacturer: boolean;

  /**
   * Indicates if we should redirect to a different page on button click or show a drawer
   */
  shouldRedirect?: boolean;
} & ExpectedQuery;
export type SignupFormProps = Partial<Props>;
export const SignupForm = ({
  firstName,
  lastName,
  email,
  company,
  shouldRedirect = false,
  overrideFormAction,
  userInviteToken,
  pendingUserToken,
  disableCompanyNameEdit,
  isBuyer,
  isSupplier,
  isManufacturer
}: SignupFormProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const [allValues, setAllValues] = useState({
    firstName: firstName || searchParams.get('firstName') || '',
    lastName: lastName || searchParams.get('lastName') || '',
    email: email || searchParams.get('email') || '',
    companyName: company || searchParams.get('company') || '',
    password: '',
    areTermsAccepted: false
  });
  const [isSending, setIsSending] = useState(false);
  const handleExistingEmailError = () => {
    shouldRedirect ? router.push(`/login?headerTextsType=initialEmail&initialEmail=${allValues.email}`) : setActiveDrawer(<LoginView viewType="drawer" initialEmail={allValues.email} />);
  };
  const {
    submitSignupRequest,
    errorMessage
  } = useSignupRequest(handleExistingEmailError);
  const changeHandler: InputProps['onChange'] = e => {
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value
    });
  };
  const checkedHandler: SwitchBaseProps['onChange'] = e => {
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.checked
    });
  };
  useEffect(() => {
    recordEvent('view_sign_up_form');
  }, []);
  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault();
    if (isSending) {
      return;
    }
    setIsSending(true);
    await submitSignupRequest({
      ...allValues,
      isBuyer: typeof isBuyer === 'boolean' ? isBuyer : true,
      isSupplier: Boolean(isSupplier),
      isManufacturer: Boolean(isManufacturer),
      userInviteToken,
      pendingUserToken,
      referralUrl: document.referrer
    }, overrideFormAction);
    setIsSending(false);
  };
  return <Box id="signup-form" component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="SignupForm" data-sentry-source-file="SignupForm.tsx">
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <Box sx={{
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: 2
    }} data-sentry-element="Box" data-sentry-source-file="SignupForm.tsx">
        <Box component="fieldset" sx={{
        p: 0,
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        gap: 2,
        border: 'none'
      }} data-sentry-element="Box" data-sentry-source-file="SignupForm.tsx">
          <TextField type="text" name="firstName" label="First Name" value={allValues.firstName} onChange={changeHandler} autoFocus={true} required fullWidth data-sentry-element="TextField" data-sentry-source-file="SignupForm.tsx" />
          <TextField type="text" name="lastName" label="Last Name" value={allValues.lastName} onChange={changeHandler} autoFocus={false} required fullWidth data-sentry-element="TextField" data-sentry-source-file="SignupForm.tsx" />
        </Box>

        <TextField fullWidth type="text" name="companyName" label="Your company name" value={allValues.companyName} onChange={changeHandler} disabled={disableCompanyNameEdit} autoFocus={false} inputProps={{
        maxLength: 60
      }} required data-sentry-element="TextField" data-sentry-source-file="SignupForm.tsx" />
        <TextField fullWidth type="email" name="email" label="Work email address" value={allValues.email} onChange={changeHandler} autoFocus={false} required data-sentry-element="TextField" data-sentry-source-file="SignupForm.tsx" />
        <TextField fullWidth type="password" name="password" label="Password" value={allValues.password} onChange={changeHandler} autoFocus={false} required data-sentry-element="TextField" data-sentry-source-file="SignupForm.tsx" />
        <FormControlLabel control={<Checkbox required name="areTermsAccepted" checked={allValues.areTermsAccepted} value={allValues.areTermsAccepted} onChange={checkedHandler} />} label={<Typography variant="bodyMedium">
              I agree to Quotebeam{' '}
              <MuiLink href="/info/privacy" target="_blank" sx={{
          color: 'primary.main'
        }}>
                Privacy Policy
              </MuiLink>{' '}
              and{' '}
              <MuiLink href="/info/tos" target="_blank" sx={{
          color: 'primary.main'
        }}>
                Terms and Conditions
              </MuiLink>
              .
            </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="SignupForm.tsx" />

        <LoadingButton loading={isSending} fullWidth variant="contained" size="large" type="submit" value="submit" sx={{
        my: {
          xs: 1,
          md: 2
        }
      }} data-sentry-element="LoadingButton" data-sentry-source-file="SignupForm.tsx">
          Get started
        </LoadingButton>
      </Box>
      <Box sx={{
      justifySelf: 'start',
      mt: '10px',
      display: 'flex'
    }} data-sentry-element="Box" data-sentry-source-file="SignupForm.tsx">
        <Typography component="span" variant="bodyMedium" sx={{
        mr: 0.5
      }} data-sentry-element="Typography" data-sentry-source-file="SignupForm.tsx">
          Already registered? Please
        </Typography>
        <MuiLink href="/login" onClick={() => setActiveDrawer(<LoginView viewType="drawer" />)} component={LinkOnClickOrRedirect} shouldRedirect={shouldRedirect} variant="bodyMedium" underline="always" color="primary.main" data-sentry-element="MuiLink" data-sentry-source-file="SignupForm.tsx">
          Log in
        </MuiLink>
      </Box>
    </Box>;
};