'use client';

import { PropsWithChildren, useMemo } from 'react';
import { CssBaseline, CssVarsProvider, extendTheme } from '@mui/material';
import { AppCacheProvider, EmotionCacheProviderProps } from '@mui/material-nextjs/v14-pagesRouter';
import { deepmerge } from '@mui/utils';
import { addSSRMatchMedia } from '@qb/base-theme/base-theme-utils';
import { qbThemeOptions } from '../../utils/qbTheme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#typescript
 */
type PagesRouterThemeProviderProps = PropsWithChildren<EmotionCacheProviderProps & {
  isUserAgentDeviceMobile: boolean;
}>;
export const PagesRouterThemeProvider = ({
  children,
  emotionCache,
  isUserAgentDeviceMobile
}: PagesRouterThemeProviderProps) => {
  const themeWithMatchMedia = useMemo(() => {
    const qbOptions = deepmerge(qbThemeOptions, addSSRMatchMedia(isUserAgentDeviceMobile));
    return extendTheme(qbOptions);
  }, [isUserAgentDeviceMobile]);
  return <AppCacheProvider emotionCache={emotionCache} data-sentry-element="AppCacheProvider" data-sentry-component="PagesRouterThemeProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
      <CssVarsProvider theme={themeWithMatchMedia} data-sentry-element="CssVarsProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="PagesRouterThemeProvider.tsx" />
        {children}
      </CssVarsProvider>
    </AppCacheProvider>;
};